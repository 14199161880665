import { template as template_9b865c3c717b4e6d835bb90c0e227dca } from "@ember/template-compiler";
const FKControlMenuContainer = template_9b865c3c717b4e6d835bb90c0e227dca(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
