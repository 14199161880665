import { template as template_0011d7f5ddfc4d96a1242a52ee037cca } from "@ember/template-compiler";
const FKLabel = template_0011d7f5ddfc4d96a1242a52ee037cca(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
